import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../pages/Home/Index.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/signUp',
    name: 'signUp',
    component: () => import('../pages/signUp/Index.vue')
  },
  {
    path: '/signUpNew',
    name: 'signUpNew',
    component: () => import('../pages/signUpNew/Index.vue')
  },
  {
    path: '/signUpMobile',
    name: 'signUpMobile',
    component: () => import('../pages/signUpMobile/Index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
